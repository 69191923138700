import React, { useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import {
  GridContain,
  Grid,
  Product,
  Title,
  PriceTag,
  DiscountedPriceTag,
  PriceArea,
  Button,
  CollectionReviewStars
} from './styles'

import { Img } from '../../utils/styles'
import { Price } from '../../components/Price';

import { ProductStarsWidget, useYotpo } from '../Reviews';

const SaleDetail = ({tag,title}) => {
  const { admin, shopifyCollection } = useStaticQuery(
    graphql`
      query {
        shopifyCollection(handle: {eq: "get-out-of-the-house"}) 
        {
        products {
            id
            title
            handle
            createdAt
            tags
            images {
              id
              originalSrc
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            variants {
              price
              compareAtPrice
              product {
                handle
              }
            }
          }
        }
        admin {
          collectionByHandle(handle: "get-out-of-the-house") {
            products(first: 18) {
              edges {
                node {
                  id
                  handle
                }
              }
            }
          }
        }
      }
    `
  )

  const adminProducts = {};
  admin.collectionByHandle.products.edges.forEach(product => {
    let id = product.node.id.split('/');
    id = id[id.length-1];

    adminProducts[product.node.handle] = id;
  });

  useEffect(useYotpo(true));

  return (
    <GridContain>
        <h3>{tag}</h3>
        <h1>{title}</h1>
      <Grid>
        { shopifyCollection.products
          ? shopifyCollection.products.map(({ id, handle, title, tags, images: [firstImage], variants: [firstVariant] } ) => {
            
            const filteredTitle = title.replace('Copper Compression','');

            return (
            <Product key={ id } >
              <Link to={ `/products/${handle}/` }>
                { firstImage && firstImage.localFile &&
                  (<Img
                    fluid={{ ...firstImage.localFile.childImageSharp.fluid, aspectRatio: 1/1 }}
                    alt={ handle }
                  />) }
              </Link>
              <Link to={ `/products/${handle}/` }>
                <Title>{ filteredTitle }</Title>
                <CollectionReviewStars>
                  <ProductStarsWidget handle={handle} parentVariant={adminProducts[handle]} />
                </CollectionReviewStars>
                <PriceArea>
                  <Price variant={firstVariant} tags={tags} TagWrapper={PriceTag} TagInner={DiscountedPriceTag} />
                  <Button>Buy Now</Button>
                </PriceArea>
              </Link>
            </Product>
          )})
          : <p>No Products found!</p> }
      </Grid>
    </GridContain>
  )
}

export default SaleDetail
