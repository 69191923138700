import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/HouseSale'
import styled from '@emotion/styled';

import { ImitationsBlockStyle, Container } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'

import hero from '../../images/sales/house-header.jpg';
import heroMobile from '../../images/sales/house-mobile.jpg';
import detailImg from '../../images/icons/copper-icon-reusable.png';

const DesktopImg = styled.img`
    width: 100%;
    display: none;
    @media (min-width: ${breakpoints.m}px){
      display: block;
    }
`

const MobileImg = styled.img`
    width: 100%;
    display: block;
    @media (min-width: ${breakpoints.m}px){
      display: none;
    }
`

const DetailImg = styled.img`
    width: 75px;
    margin: auto;
    margin-bottom: 40px;
    display: block;
`

const ImageBlock = (props) => (
  <>
    <DesktopImg alt={props.title} src={props.img} />
    <MobileImg alt={props.title} src={props.mobileImg} />
  </>
)

const ImitationsBlock = () => (
  <ImitationsBlockStyle className='imitations-block' style={{textAlign: 'center'}}>
    <h3>Get back to what moves you!</h3>
    <h1>Save 20% sitewide</h1>
    <p>As the weather warms up and places begin to open, use 20% off of the products that'll allow you to cherish the nicest months of the year. Don't wait, savings end on Monday!</p>
    {/* <ButtonStyle>
        <a href={"/sales/get-out-of-the-house?discount=HOUSE20"}>Shop Now!</a>
    </ButtonStyle> */}
  </ImitationsBlockStyle>
)

const SalePage = () => {
  return (
    <Container>
      <SEO title="Get out of the house!" description="As the weather warms up and places begin to open, use 20% off of the products that'll allow you to cherish the nicest months of the year. Don't wait, savings end on Monday!" keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />
      <ImageBlock img={hero} mobileImg={heroMobile} title="Get out of the house sale" />
      <ImitationsBlock />
      <DetailImg src={detailImg} />
      <SaleDetail tag='Get out of the house!' title='Save 20% Sitewide'/>
    </Container>
  )
}

export default SalePage
